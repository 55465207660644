import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { Delete } from '@mui/icons-material'
import { Card, Grid, MenuItem, Select, SelectChangeEvent, TableContainer } from '@mui/material'

import ConfirmDialog from '../../../../../components/ConfirmDialog/ConfirmDialog'
import { GRTable, GRTableColumn } from '../../../../../components/GRTable/GRTable'
import { insertAtIndex } from '../../../../../helpers/arrays'
import { ConfirmDialogData } from '../../../../../types/ConfirmDialogData'
import { useGetTrackedGameReportsQuery } from '../../../../api/internal'
import { ActionButton } from '../../../../components/ActionButton/ActionButton'
import { InternalEventOverview } from '../../../../types/InternalEventOverview'
import { InternalTrackedGameReport } from '../../../../types/InternalTrackedGameReport'

type TrackedGameLinkedReportTableProps = {
  form: UseFormReturn<InternalEventOverview, any, undefined>
}

export const TrackedGameLinkedReportTable: FC<TrackedGameLinkedReportTableProps> = ({ form }) => {
  const { data: reportsList } = useGetTrackedGameReportsQuery()
  const { t } = useTranslation()
  const { control, getValues, watch } = form
  watch()
  const selectedReports = reportsList?.filter((report) => getValues().review.reportIds?.includes(report.id))
  let options = reportsList?.filter((report) => !selectedReports?.includes(report)).sort((a, b) => a.title.en.localeCompare(b.title.en)) || []
  options = insertAtIndex(options, { title: { en: t('internal-live-events:add_report') }, id: '1' }, 0)

  return (
    <>
      <Grid container spacing={2} direction="column">
        <Grid container item xs={12} alignItems="center" justifyContent="space-between" direction="row">
          <Grid item style={{ fontWeight: 700 }} xs={6}>
            {t('internal-live-events:linked_reports')}
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="review.reportIds"
              control={control}
              render={({ field }) => {
                return <ReportSelector value={field.value} onChange={field.onChange} options={options} />
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="review.reportIds"
            control={control}
            render={({ field }) => {
              return <ReportListTable value={field.value} reports={selectedReports} onChange={field.onChange} />
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

type ReportSelectorProps = {
  value?: string[]
  onChange: (value: string[]) => void
  options: InternalTrackedGameReport[]
}

export const ReportSelector: FC<ReportSelectorProps> = ({ value = [], onChange, options }) => {
  const [selectedRaport] = useState<string>('1')
  const handleChange = (event: SelectChangeEvent) => {
    const newValue = [...value, event.target.value]
    onChange(newValue)
  }

  return (
    <Select size="small" value={selectedRaport} onChange={handleChange} fullWidth>
      {options.map((option) => (
        <MenuItem value={option.id} key={option.title.en}>
          {option.title.en}
        </MenuItem>
      ))}
    </Select>
  )
}

type ReportListTableProps = {
  value?: string[]
  reports: InternalTrackedGameReport[] | undefined
  isLoading?: boolean
  onChange: (value: string[]) => void
}

type ReportDeleteConfirmData = {
  reportId: string
  name: string
}

export const ReportListTable: FC<ReportListTableProps> = ({ value = [], reports, isLoading, onChange }) => {
  const { t } = useTranslation()
  const containerRef = useRef(null)
  const [deleteReportDialogData, setDeleteReportDialogData] = useState<ConfirmDialogData<ReportDeleteConfirmData>>()

  const handleDeleteReport = useCallback(
    (data: ReportDeleteConfirmData) => {
      const title = `${t('internal-live-events:delete_report_confirm_dialog_title')}: ${data.name}`
      const confirmText = `${t('internal-live-events:delete_report_confirm_dialog_confirm_text_part1')}
        ${t('internal-live-events:delete_report_confirm_dialog_confirm_text_part2')}`

      setDeleteReportDialogData({
        title: title,
        confirmText: confirmText,
        actionText: t('internal-common:yes'),
        cancelText: t('internal-common:no'),
        destructiveAction: true,
        data,
      })
    },
    [t]
  )

  const reportColums: GRTableColumn<InternalTrackedGameReport, typeof customTableProps>[] = useMemo(
    () => [
      {
        headerCellProps: { sx: { width: 240, textAlign: 'left' } },
        labelAccessor: () => <Trans i18nKey="internal-live-events:report_name" />,
        cellProps: { sx: { fontWeight: 700, textAlign: 'left' } },
        accessor: ({ row }) => row.title.en,
        sortable: true,
        sortAccessor: ({ row }) => row.title.en,
      },
      {
        headerCellProps: { sx: { width: 60 } },
        labelAccessor: () => <Trans i18nKey="internal-live-events:actions" />,
        accessor: ({ row }) => (
          <ActionButton color="warning" onClick={() => handleDeleteReport({ reportId: row.id, name: row.title.en })}>
            <Delete fontSize="small" />
          </ActionButton>
        ),
      },
    ],
    [handleDeleteReport]
  )

  const handleDeleteReportConfirm = (dialogData?: ConfirmDialogData<ReportDeleteConfirmData>) => {
    if (dialogData?.data) {
      const newValue = value.filter((value) => value !== dialogData.data?.reportId)
      onChange(newValue)
    }

    setDeleteReportDialogData(undefined)
  }

  const customTableProps = useMemo(() => ({}), [])
  const [columns, setColumns] = useState<GRTableColumn<InternalTrackedGameReport, typeof customTableProps>[]>(reportColums)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<InternalTrackedGameReport, typeof customTableProps>[]) => {
    setColumns(updatedColumns)
  }, [])

  return (
    <>
      <TableContainer component={Card} ref={containerRef}>
        <GRTable
          columns={columns}
          isLoading={isLoading}
          onColumnsUpdated={handleColumnsUpdate}
          rowIdKey={(event) => event.id}
          rows={reports || []}
          scroller={containerRef}
          striped
          noRowsLabel={t('internal-live-events:no_reports_added_yet')}
        />
      </TableContainer>
      <ConfirmDialog open={!!deleteReportDialogData} onClose={handleDeleteReportConfirm} confirmDialogData={deleteReportDialogData} />
    </>
  )
}
