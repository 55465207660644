import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { FormControl } from '@mui/material'

import { SelectMenu, SelectMenuProps } from '../../../../../../components/SelectMenu/SelectMenu'
import { Currency } from '../../../../../types/Currency'

type PricePointSelectMenuProps = Omit<SelectMenuProps<Currency['name']>, 'label' | 'options'> & {
  value: Currency['name']
  onChange: (name: Currency['name']) => void
  currencies: Currency[]
}

export const pricePointDefaultCurrency = 'usd'

export const PricePointSelectMenu: FC<PricePointSelectMenuProps> = ({ value, onChange, currencies, ...selectMenuProps }) => {
  const { t } = useTranslation()
  const options = [
    { name: pricePointDefaultCurrency.toUpperCase(), value: pricePointDefaultCurrency },
    ...currencies.map((option) => ({ name: option.name, value: option.id })),
  ]

  return (
    <FormControl size="small" variant="outlined" fullWidth>
      <SelectMenu {...selectMenuProps} label={t('internal-common:currency')} value={value} onChange={(e) => onChange(e.target.value)} options={options} />
    </FormControl>
  )
}
