import { FC, ReactNode, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { ThemeProvider } from '@emotion/react'

import { useInternalAccessCheck } from '../../../features/account/hooks/roleHooks'
import { internalTheme } from '../../theme/theme-internal'

type InternalRouteProps = {
  children?: ReactNode
}

export const InternalRoute: FC<InternalRouteProps> = ({ children }) => {
  const navigate = useNavigate()
  const hasInternalAccess = useInternalAccessCheck()

  useEffect(() => {
    if (!hasInternalAccess) {
      // No access to internal tools, redirect to public app index page
      navigate('/')
    }
  }, [hasInternalAccess, navigate])

  return (
    <ThemeProvider theme={internalTheme}>
      <Outlet />
    </ThemeProvider>
  )
}
