import { t } from 'i18next'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Add } from '@mui/icons-material'
import { Grid, TextField } from '@mui/material'

import { ActionButton } from '../../../../../components/ActionButton/ActionButton'
import { Currency } from '../../../../../types/Currency'
import { PricePoint } from '../../../types/PricePoint'
import { PricePointSelectMenu } from '../PricePointSelectMenu/PricePointSelectMenu'

type PricePointAdderProps = {
  pricePoints: PricePoint[]
  onAddPricePoint: (newPricePoint: { value: number; currencyId: string }) => void
  currencies: Currency[] | undefined
}

export const PricePointAdder: FC<PricePointAdderProps> = ({ pricePoints, currencies, onAddPricePoint }) => {
  const defaultValues = { price: '', currencyId: '' }
  const {
    control,
    getValues,
    watch,
    reset,
    formState: { isValid },
  } = useForm({
    defaultValues: defaultValues,
  })

  watch()

  const isValidNumber = () => {
    return Number.isNaN(+getValues('price'))
  }

  const isDuplicate = () => {
    const duplicate = pricePoints.filter((pricePoint) => pricePoint.currencyId === getValues('currencyId') && pricePoint.value === Number(getValues('price')))
    return duplicate.length ? true : false
  }

  const addNewPricePoint = () => {
    const pricePointValues = {
      value: +getValues('price'),
      currencyId: getValues('currencyId'),
    }
    onAddPricePoint(pricePointValues)
    reset(defaultValues)
  }

  return (
    <Grid container spacing={2} alignItems="center" wrap="wrap">
      <Grid item xs={3}>
        <Controller
          name="price"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <TextField
                required
                type="text"
                value={field.value}
                onChange={field.onChange}
                variant="outlined"
                size="small"
                label={t('internal-live-events:price_point')}
                fullWidth
                error={isValidNumber() || isDuplicate()}
                helperText={
                  isValidNumber()
                    ? t('internal-live-events:enter_valid_number')
                    : null || isDuplicate()
                    ? t('internal-live-events:duplicate_price_point')
                    : null
                }
              />
            )
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          name={'currencyId'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return <PricePointSelectMenu required value={field.value || ''} onChange={field.onChange} currencies={currencies || []} autoWidth={false} />
          }}
        />
      </Grid>
      <Grid item sx={{ ml: 'auto' }}>
        <ActionButton onClick={addNewPricePoint} disabled={!(isValid && !isValidNumber() && !isDuplicate())}>
          <Add fontSize="small" />
        </ActionButton>
      </Grid>
    </Grid>
  )
}
