import { FC, useRef, useMemo, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Delete } from '@mui/icons-material'
import { TableContainer, Box } from '@mui/material'

import ConfirmDialog from '../../../../../../components/ConfirmDialog/ConfirmDialog'
import { GRTableColumn, GRTable } from '../../../../../../components/GRTable/GRTable'
import { ConfirmDialogData } from '../../../../../../types/ConfirmDialogData'
import { ActionButton } from '../../../../../components/ActionButton/ActionButton'
import { Currency } from '../../../../../types/Currency'
import { PricePoint } from '../../../types/PricePoint'
import { pricePointDefaultCurrency } from '../PricePointSelectMenu/PricePointSelectMenu'

type PricePointTableProps = {
  pricePoints: PricePoint[]
  currencies: Currency[] | undefined
  onRemovePricePoint: (pricePoint: PricePoint) => void
}

type removePricePointDialogData = {
  pricePoint: PricePoint
}

export const PricePointTable: FC<PricePointTableProps> = ({ pricePoints, currencies, onRemovePricePoint }) => {
  const { t } = useTranslation()
  const containerRef = useRef(null)
  const [removePricePointDialogData, setRemovePricePointDialogData] = useState<ConfirmDialogData<removePricePointDialogData>>()

  const handleRemovePricePoint = useCallback(
    (pricePoint: PricePoint) => {
      setRemovePricePointDialogData({
        title: t('internal-live-events:remove_price_point_confirm_dialog_title'),
        confirmText: t('internal-live-events:remove_price_point_confirm_dialog_confirm_text'),
        actionText: t('internal-common:yes'),
        cancelText: t('internal-common:no'),
        destructiveAction: true,
        data: { pricePoint },
      })
    },
    [t]
  )

  const handleRemovePricePointConfirmed = (dialogData?: ConfirmDialogData<removePricePointDialogData>) => {
    if (dialogData?.data) {
      onRemovePricePoint(dialogData.data.pricePoint)
    }

    setRemovePricePointDialogData(undefined)
  }

  const pricePointColums: GRTableColumn<PricePoint, typeof customProps>[] = useMemo(() => {
    return [
      {
        headerCellProps: { sx: { textAlign: 'left' } },
        labelAccessor: () => t('internal-live-events:price_point'),
        cellProps: { sx: { fontWeight: 700, textAlign: 'left' } },
        accessor: ({ row }) => row.value,
        sortable: true,
        sortAccessor: ({ row }) => row.value,
      },
      {
        headerCellProps: { sx: { textAlign: 'left' } },
        labelAccessor: () => t('internal-common:currency'),
        cellProps: { sx: { textAlign: 'left' } },
        accessor: ({ customTableProps, row }) => {
          let currencyName =
            row.currencyId === pricePointDefaultCurrency ? null : customTableProps?.currencies?.find((currency) => currency.id === row.currencyId)
          return currencyName ? currencyName.name : pricePointDefaultCurrency.toUpperCase()
        },

        sortable: true,
      },
      {
        labelAccessor: () => t('internal-live-events:actions'),
        accessor: ({ row, customTableProps }) => (
          <ActionButton color="warning" onClick={() => customTableProps?.handleRemovePricePoint(row)}>
            <Delete fontSize="small" />
          </ActionButton>
        ),
      },
    ]
  }, [t])

  const customProps = useMemo(() => ({ currencies, handleRemovePricePoint }), [currencies, handleRemovePricePoint])

  const [columns, setColumns] = useState<GRTableColumn<PricePoint, typeof customProps>[]>(pricePointColums)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<PricePoint, typeof customProps>[]) => {
    setColumns(updatedColumns)
  }, [])

  return (
    <>
      <TableContainer component={Box} ref={containerRef}>
        <GRTable
          columns={columns}
          isLoading={false}
          onColumnsUpdated={handleColumnsUpdate}
          customProps={customProps}
          rowIdKey={(pricePoint) => pricePoint.currencyId}
          rows={pricePoints}
          scroller={containerRef}
          noRowsLabel={t('internal-live-events:no_price_points')}
          striped
          gridlines
        />
      </TableContainer>
      <ConfirmDialog open={!!removePricePointDialogData} onClose={handleRemovePricePointConfirmed} confirmDialogData={removePricePointDialogData} />
    </>
  )
}
