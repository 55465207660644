import { FC, useCallback } from 'react'
import { Trans } from 'react-i18next'

import { Card, CardContent, CardHeader, Divider } from '@mui/material'

import { Currency } from '../../../../types/Currency'
import { PricePoint } from '../../types/PricePoint'
import { PricePointAdder } from './PricePointAdder/PricePointAdder'
import { PricePointTable } from './PricePointTable/PricePointTable'

type PricePointContainerProps = {
  onChange: (pricePoins: PricePoint[]) => void
  currencies: Currency[] | undefined
  pricePoints: PricePoint[]
}

export const PricePointContainer: FC<PricePointContainerProps> = ({ pricePoints, onChange, currencies }) => {
  const handleAddPricePoints = useCallback(
    (newPricePoint: { value: number; currencyId: string }) => {
      const newPricePointList = [...pricePoints, newPricePoint]
      onChange(newPricePointList)
    },
    [onChange, pricePoints]
  )
  const handleRemovePricePoint = useCallback(
    (pricePoint: PricePoint) => {
      const newPricePointList = pricePoints.filter(
        (filterPricePoint) => !(filterPricePoint.currencyId === pricePoint.currencyId && filterPricePoint.value === pricePoint.value)
      )
      onChange(newPricePointList)
    },
    [onChange, pricePoints]
  )

  return (
    <>
      <Card>
        <CardHeader title={<Trans i18nKey="internal-live-events:price_points" />} />
        <Divider />
        <PricePointTable pricePoints={pricePoints} currencies={currencies} onRemovePricePoint={handleRemovePricePoint} />
        <CardHeader subheader={<Trans i18nKey="internal-live-events:add_new_price_point" />} sx={{ pb: 1 }} />
        <CardContent>
          <PricePointAdder pricePoints={pricePoints} currencies={currencies} onAddPricePoint={handleAddPricePoints} />
        </CardContent>
      </Card>
    </>
  )
}
