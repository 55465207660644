import { t } from 'i18next'
import React, { FC, useCallback, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Trans } from 'react-i18next'

import { Delete } from '@mui/icons-material'
import { Grid, Typography, TextField, InputAdornment } from '@mui/material'

import ConfirmDialog from '../../../../../components/ConfirmDialog/ConfirmDialog'
import { ConfirmDialogData } from '../../../../../types/ConfirmDialogData'
import { ActionButton } from '../../../../components/ActionButton/ActionButton'
import { ErrorStatusCircle } from '../../../../components/ErrorStatusCircle/ErrorStatusCircle'
import { isEmpty } from '../../../../helpers/isEmpty'
import { isNumber } from '../../../../helpers/isNumber'
import { Currency } from '../../../../types/Currency'
import './PricingDataFlexContainer.scss'

type CurrencyDisplayProps = {
  currencies: Currency[] | undefined
  onChange: (currencies: Currency[]) => void
}

type removeCurrencyDialogData = {
  name: string
}

export const CurrencyDisplay: FC<CurrencyDisplayProps> = ({ currencies, onChange }) => {
  const { control, watch } = useFormContext<{ currencies: Currency[] }>()
  const formCurrencies = watch('currencies')
  const [removeCurrencyDialogData, setRemoveCurrencyDialogData] = useState<ConfirmDialogData<removeCurrencyDialogData>>()
  const removeCurrency = useCallback((name: string) => {
    setRemoveCurrencyDialogData({
      title: t('internal-live-events:remove_currency_confirm_dialog_title'),
      confirmText: t('internal-live-events:remove_currency_confirm_dialog_confirm_text'),
      actionText: t('internal-common:yes'),
      cancelText: t('internal-common:no'),
      destructiveAction: true,
      data: { name },
    })
  }, [])

  const handleRemoveCurrencyConfirm = (dialogData?: ConfirmDialogData<removeCurrencyDialogData>) => {
    if (dialogData?.data) {
      if (currencies) {
        const newCurrencyList = currencies.filter((currency) => currency.name !== dialogData.data?.name)
        onChange(newCurrencyList)
      }
    }

    setRemoveCurrencyDialogData(undefined)
  }

  const isUniqueCurrencyName = (name: string) => {
    const duplicates = formCurrencies.filter((currency) => currency.name.trimEnd() === name.trimEnd())
    return duplicates.length < 2 ? true : false
  }

  return (
    <>
      {currencies?.length || -1 > 0 ? (
        <Grid item xs={12}>
          <Typography variant="h3">
            <Trans i18nKey="internal-live-events:currencies" />
          </Typography>
        </Grid>
      ) : null}
      {formCurrencies.map((currency, index) => (
        <React.Fragment key={currency.id}>
          <Grid item xs={3.7}>
            <Controller
              name={`currencies.${index}.name`}
              control={control}
              rules={{ required: true, validate: isUniqueCurrencyName }}
              render={({ field }) => {
                return (
                  <TextField
                    required
                    type="text"
                    value={field.value}
                    onChange={field.onChange}
                    variant="outlined"
                    size="small"
                    label={t('internal-live-events:currency_name')}
                    fullWidth
                    error={!isUniqueCurrencyName(field.value) || isEmpty(field.value)}
                    InputProps={{
                      endAdornment:
                        !isUniqueCurrencyName(field.value) || isEmpty(field.value) ? (
                          <InputAdornment position="end">
                            <ErrorStatusCircle errorText={t('internal-live-events:invalid_input')} />
                          </InputAdornment>
                        ) : null,
                    }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={3.7}>
            <Controller
              name={`currencies.${index}.usd`}
              control={control}
              rules={{
                required: true,
                validate: isNumber,
              }}
              render={({ field }) => {
                return (
                  <TextField
                    required
                    type="text"
                    value={field.value}
                    onChange={field.onChange}
                    variant="outlined"
                    size="small"
                    label={t('internal-live-events:usd')}
                    fullWidth
                    error={!isNumber(field.value) || isEmpty(field.value)}
                    InputProps={{
                      endAdornment:
                        !isNumber(field.value) || isEmpty(field.value) ? (
                          <InputAdornment position="end">
                            <ErrorStatusCircle errorText={t('internal-live-events:invalid_input')} />
                          </InputAdornment>
                        ) : null,
                    }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={3.7}>
            <Controller
              name={`currencies.${index}.value`}
              control={control}
              rules={{ required: true, validate: isNumber }}
              render={({ field }) => {
                return (
                  <TextField
                    required
                    type="text"
                    value={field.value}
                    onChange={field.onChange}
                    variant="outlined"
                    size="small"
                    label={t('internal-live-events:in_game_currency')}
                    fullWidth
                    error={!isNumber(field.value) || isEmpty(field.value)}
                    InputProps={{
                      endAdornment:
                        !isNumber(field.value) || isEmpty(field.value) ? (
                          <InputAdornment position="end">
                            <ErrorStatusCircle errorText={t('internal-live-events:invalid_input')} />
                          </InputAdornment>
                        ) : null,
                    }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={0.9}>
            <ActionButton color="warning" onClick={() => removeCurrency(currency.name)}>
              <Delete fontSize="small" />
            </ActionButton>
          </Grid>
        </React.Fragment>
      ))}
      <ConfirmDialog open={!!removeCurrencyDialogData} onClose={handleRemoveCurrencyConfirm} confirmDialogData={removeCurrencyDialogData} />
    </>
  )
}
