import { startOfDay, endOfDay } from 'date-fns'
import { t } from 'i18next'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { UTCDate } from '@date-fns/utc'
import { ContentCopy } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Fade,
  FormControlLabel,
  Grid,
  Paper,
  SxProps,
  TextField,
  Theme,
  Zoom,
} from '@mui/material'

import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog'
import GRCircularProgress from '../../../components/GRCircularProgress/GRCircularProgress'
import { GameCardContent } from '../../../features/game/components/GameCard/GameCard'
import { displaySnackBar } from '../../../features/snackbar'
import { getUTCDate } from '../../../helpers/date'
import { useAppDispatch } from '../../../hooks/storeHooks'
import usePage from '../../../hooks/usePage'
import { useRequiredParams } from '../../../hooks/useRequiredParams'
import { useSticky } from '../../../hooks/useSticky'
import pageService from '../../../services/PageService'
import { ConfirmDialogData } from '../../../types/ConfirmDialogData'
import {
  useDeleteTrackedGameEventMutation,
  useGetTrackedGameEventQuery,
  useGetTrackedGameQuery,
  useLazyGetTrackedGameEventQuery,
  useUpdateTrackedGameEventMutation,
} from '../../api/internal'
import { ActionButton } from '../../components/ActionButton/ActionButton'
import { InputTranslator, translationLanguagesToShow } from '../../components/InputTranslator/InputTranslator'
import { AnalystsSelectMenu } from '../../features/live-events-tracking/components/AnalystsSelectMenu/AnalystsSelectMenu'
import { EventDurationsContainer } from '../../features/live-events-tracking/components/EventDurationsContainer/EventDurationsContainer'
import { EventScreenshotsContainer } from '../../features/live-events-tracking/components/EventScreenshotsContainer/EventScreenshotsContainer'
import { EventTypesSelectMenu } from '../../features/live-events-tracking/components/EventTypesSelectMenu/EventTypesSelectMenu'
import { LiveEventDescriptionAIAssistant } from '../../features/live-events-tracking/components/LiveEventDescriptionAIAssistant/LiveEventDescriptionAIAssistant'
import { LiveEventSecondaryTaxonomySelector } from '../../features/live-events-tracking/components/LiveEventSecondaryTaxonomySelector/LiveEventSecondaryTaxonomySelector'
import { PricePointContainer } from '../../features/live-events-tracking/components/PricePointContainer/PricePointContainer'
import { gameEventTypesSelectMenuAllValue } from '../../features/live-events-tracking/components/TrackedGamesQuickSearchEvent/GameEventTypesSelectMenu/GameEventTypesSelectMenu'
import {
  QuickSearchFilters,
  TrackedGamesQuickSearchEvent,
} from '../../features/live-events-tracking/components/TrackedGamesQuickSearchEvent/TrackedGamesQuickSearchEvent'
import { Analyst } from '../../features/live-events-tracking/components/TrackedGamesTable/TrackedGamesTable'
import { hasOverlappingDurations } from '../../features/live-events-tracking/helpers/eventDuration'
import { EventEditForm, EventEditFormDurations } from '../../features/live-events-tracking/types/EventEditForm'
import { useAnalystById } from '../../hooks/useAnalystById'
import { InputTranslatorType } from '../../types/InputTranslatorType'
import { ContentLanguageTranslation } from '../../types/InternalAnalystComment'
import { InternalTrackingEvent, InternalTrackingEventEvent } from '../../types/InternalTrackingEvent'

type TrackedGameEventEditPageProps = {
  children?: ReactNode
}

type DeleteEventConfirmDialogData = {
  eventId: string
  gameId: string
}

export const TrackedGameEventEditPage: FC<TrackedGameEventEditPageProps> = ({ children }) => {
  usePage(pageService.internalPages.find((page) => page.id === 'internal-tracked-game-events-edit-event'))
  const dispatch = useAppDispatch()
  const { trackedGameId, eventId } = useRequiredParams<{ trackedGameId: string; eventId: string }>()
  const trackedGameQuery = useGetTrackedGameQuery(trackedGameId)
  const trackedGameEventQuery = useGetTrackedGameEventQuery(eventId, { skip: !eventId })
  const [saveEvent] = useUpdateTrackedGameEventMutation()
  const [deleteEvent] = useDeleteTrackedGameEventMutation()
  const [getEventToCopyValuesFrom] = useLazyGetTrackedGameEventQuery()
  const [deleteEventConfirmDialogData, setDeleteEventConfirmDialogData] = useState<ConfirmDialogData<DeleteEventConfirmDialogData>>()
  const [notUploadedScreenshotFiles, setNotUploadedScreenshotFiles] = useState<boolean>(false)
  const onScreenshotSelectionChange = (notUploadedFiles: boolean) => {
    setNotUploadedScreenshotFiles(notUploadedFiles)
  }

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const navigateBack = () => {
    if (location.key === 'default') {
      navigate(
        {
          pathname: `/internal/live-events-tracking/events/${trackedGameId}`,
          search: searchParams.get('date') ? `date=${searchParams.get('date')}` : '',
        },
        { replace: true }
      )
    } else {
      navigate(-1)
    }
  }

  const defaultEvent = useMemo(
    () => ({
      durations: {},
      event: {
        gameId: trackedGameId,
        name: '',
        typeId: '',
        comment: {
          analystId: '',
          content: {
            comment: {
              en: '',
              ja: '',
              zh: '',
            },
          },
        },
        pricePoints: [],
        screenshotUrls: [],
        tags: [],
        highlighted: false,
        active: false,
      },
    }),
    [trackedGameId]
  )

  const form = useForm<EventEditForm>({ reValidateMode: 'onChange', defaultValues: defaultEvent })
  const {
    control,
    getValues,
    handleSubmit,
    formState: { isValid },
    reset,
    watch,
    setValue,
  } = form
  const quickSearchEventForm = useForm<QuickSearchFilters>({
    defaultValues: { eventName: '', eventTypeId: gameEventTypesSelectMenuAllValue },
  })

  const analystId = watch('event.comment.analystId')
  const selectedAnalyst = useAnalystById(analystId)

  useEffect(() => {
    reset(
      trackedGameEventQuery.data
        ? {
            event: trackedGameEventQuery.data.event,
            durations: trackedGameEventQuery.data.durations.reduce<EventEditFormDurations>((acc, duration) => {
              const startDate = new UTCDate(duration.start)
              const endDate = new UTCDate(duration.end)
              acc[duration.id as string] = {
                ...duration,
                key: duration.id as string,
                start: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()),
                end: new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()),
              }
              return acc
            }, {}),
          }
        : defaultEvent
    )
  }, [defaultEvent, reset, setValue, trackedGameEventQuery.data, trackedGameId])

  const handleSave = (event: EventEditForm) => {
    const saveableEvent = {
      ...event,
      durations: Object.values(event.durations).map(({ start, end, id }) => ({
        start: startOfDay(getUTCDate(start)).getTime(),
        end: endOfDay(getUTCDate(end)).getTime(),
        id,
      })),
    }

    if (hasOverlappingDurations(event.durations)) {
      dispatch(displaySnackBar({ message: <Trans i18nKey="internal-live-events:error_event_overlap_event_edit_page" />, severity: 'error', open: true }))
      return
    }

    saveEvent(saveableEvent)
      .unwrap()
      .then(() => {
        dispatch(displaySnackBar({ message: <Trans i18nKey="internal-live-events:event_saved_success_message" />, severity: 'success', open: true }))
        navigateBack()
      })
      .catch((error) => {
        if (error.data.errors[0].error === 'currency does not exist') {
          dispatch(displaySnackBar({ message: <Trans i18nKey="internal-live-events:currency_does_not_exist" />, severity: 'error', open: true }))
          return
        }
        dispatch(displaySnackBar({ message: <Trans i18nKey="common:general_error_message" />, severity: 'error', open: true }))
      })
  }

  const handleCopyEventValues = (event: InternalTrackingEventEvent) => {
    getEventToCopyValuesFrom(event.id as string)
      .unwrap()
      .then((event: InternalTrackingEvent) => {
        const currentEvent = getValues()
        reset({
          ...currentEvent,
          event: {
            ...currentEvent.event,
            typeId: event.event.typeId,
            tags: event.event.tags,
            comment: event.event.comment,
            name: event.event.name,
          },
        })
        quickSearchEventForm.reset({ eventName: '', eventTypeId: gameEventTypesSelectMenuAllValue })

        dispatch(displaySnackBar({ message: <Trans i18nKey="internal-common:values_copied" />, severity: 'success', open: true }))
      })
  }

  const handleDeleteEvent = () => {
    setDeleteEventConfirmDialogData({
      title: t('internal-live-events:delete_event_confirm_dialog_title'),
      confirmText: t('internal-live-events:delete_event_confirm_dialog_confirm_text'),
      actionText: t('internal-common:yes'),
      cancelText: t('internal-common:no'),
      destructiveAction: true,
      data: {
        eventId,
        gameId: trackedGameId,
      },
    })
  }

  const handleDeleteEventConfirmed = (dialogData?: ConfirmDialogData<DeleteEventConfirmDialogData>) => {
    if (dialogData?.data) {
      deleteEvent(dialogData?.data)
        .unwrap()
        .then(() => {
          dispatch(displaySnackBar({ message: <Trans i18nKey="internal-live-events:delete_event_success_message" />, severity: 'success', open: true }))
          navigateBack()
        })
        .catch(() => {
          dispatch(displaySnackBar({ message: <Trans i18nKey="common:general_error_message" />, severity: 'error', open: true }))
        })
    }

    setDeleteEventConfirmDialogData(undefined)
  }

  const isLoading = trackedGameQuery.isFetching || trackedGameEventQuery.isFetching

  return isLoading ? (
    <GRCircularProgress />
  ) : (
    <Fade in={!isLoading} timeout={500}>
      <Box>
        <Card sx={{ mb: 2 }}>
          <CardHeader title={<Trans i18nKey="internal-live-events:copy_values_from_other_event_title" />} />
          <Divider />

          <FormProvider {...quickSearchEventForm}>
            <TrackedGamesQuickSearchEvent
              renderActions={(event) => {
                return (
                  <ActionButton onClick={() => handleCopyEventValues(event)}>
                    <ContentCopy fontSize="small" />
                  </ActionButton>
                )
              }}
            />
          </FormProvider>
        </Card>
        <FormProvider {...form}>
          <Card sx={{ mb: 2 }}>
            <CardHeader
              title={
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>{trackedGameQuery.data?.game && <GameCardContent game={trackedGameQuery.data?.game} disableGameLink />}</Grid>
                  <Grid item>
                    <Zoom in={!!selectedAnalyst.data} timeout={500}>
                      <Box>
                        <Analyst analyst={selectedAnalyst.data} avatarProps={{ sx: { width: 60, height: 60 } }} />
                      </Box>
                    </Zoom>
                  </Grid>
                </Grid>
              }
            />
            <Divider />
            <CardContent>
              <Grid container wrap="wrap" spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="event.name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <TextField
                          type="text"
                          required
                          value={field.value}
                          onChange={field.onChange}
                          variant="outlined"
                          size="small"
                          label={t('internal-live-events:event_name')}
                          fullWidth
                        />
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="event.typeId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return <EventTypesSelectMenu required value={field.value} onChange={field.onChange} autoWidth={false} />
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="event.comment.analystId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return <AnalystsSelectMenu required value={field.value} onChange={field.onChange} autoWidth={false} />
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="event.comment.content.comment"
                    control={control}
                    render={({ field }) => (
                      <InputTranslator
                        value={field.value as ContentLanguageTranslation}
                        onChange={field.onChange}
                        inputType={InputTranslatorType.WYSIWYG}
                        dialogTitle={t('internal-live-events:comment')}
                        selectedLanguages={translationLanguagesToShow}
                        renderAIAssistant={() => (
                          <LiveEventDescriptionAIAssistant
                            queryParams={{
                              appName: trackedGameQuery.data?.game.resolvedName,
                              appType: trackedGameQuery.data?.game.appType,
                              eventName: getValues().event.name,
                              screenshotUrls: getValues().event.screenshotUrls,
                            }}
                            onCopy={(value) => {
                              field.onChange({ ...field.value, en: value })
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ mb: 2 }}>
            <CardHeader title={<Trans i18nKey="internal-live-events:secondary_taxonomy_title" />} />
            <Divider />
            <CardContent>
              <Controller
                name="event.tags"
                control={control}
                render={({ field }) => <LiveEventSecondaryTaxonomySelector value={field.value} onChange={field.onChange} />}
              />
            </CardContent>
          </Card>
          <Box mb={2}>
            <Controller
              name="event.pricePoints"
              control={control}
              render={({ field }) => (
                <PricePointContainer pricePoints={field.value} onChange={field.onChange} currencies={trackedGameQuery.data?.game.currencies} />
              )}
            />
          </Box>
          <Card sx={{ mb: 2 }}>
            <CardHeader title={<Trans i18nKey="internal-live-events:event_durations_title" />} />
            <Divider />
            <Controller
              name="durations"
              control={control}
              render={({ field }) => <EventDurationsContainer durations={field.value} onChange={field.onChange} gameId={trackedGameId} liveEventId={eventId} />}
            />
          </Card>
          <Box mb={2}>
            <Controller
              name="event.screenshotUrls"
              control={control}
              render={({ field }) => (
                <EventScreenshotsContainer
                  screenshots={field.value}
                  onScreenshotsUploaded={field.onChange}
                  onScreenshotSelectionChange={onScreenshotSelectionChange}
                />
              )}
            />
          </Box>

          <Divider sx={{ mb: 2 }} />

          <Grid container justifyContent="space-between">
            <Grid item>
              <Button variant="contained" color="warning" onClick={handleDeleteEvent}>
                <Trans i18nKey="internal-common:delete" />
              </Button>
            </Grid>
          </Grid>
          <ConfirmDialog open={!!deleteEventConfirmDialogData} onClose={handleDeleteEventConfirmed} confirmDialogData={deleteEventConfirmDialogData} />
          <StickyFooter onCancel={navigateBack} onSave={handleSubmit(handleSave)} saveDisabled={!isValid || notUploadedScreenshotFiles} />
        </FormProvider>
      </Box>
    </Fade>
  )
}

type StickyFooterProps = {
  onCancel: () => void
  onSave: () => void
  saveDisabled: boolean
}

const StickyFooter: FC<StickyFooterProps> = ({ saveDisabled, onCancel, onSave }) => {
  const { ref, isSticky } = useSticky<HTMLDivElement>({ threshold: [1], rootMargin: '-1px 0px' })
  const { control } = useFormContext<EventEditForm>()

  const sxProps: SxProps<Theme> = isSticky
    ? {
        position: 'sticky',
        bottom: 0,
        p: 2,
        borderRadius: '20px',
        mt: 2,
        zIndex: 1,
        transition: 'width margin 900ms',
      }
    : { position: 'sticky', bottom: 0, p: 2, borderRadius: '20px', mt: 2, zIndex: 1, transition: 'width margin 900ms' }

  const elevation = isSticky ? 10 : 1

  return (
    <Grid ref={ref} container justifyContent="space-between" sx={sxProps} component={Paper} elevation={elevation}>
      <Grid container spacing={2}>
        <Grid item>
          <Controller
            name="event.active"
            control={control}
            render={({ field }) => {
              return (
                <FormControlLabel
                  control={<Checkbox size="small" checked={field.value} onChange={field.onChange} />}
                  label={t('internal-live-events:active')}
                />
              )
            }}
          />
        </Grid>
        <Grid item>
          <Controller
            name="event.highlighted"
            control={control}
            render={({ field }) => {
              return (
                <FormControlLabel
                  control={<Checkbox size="small" checked={field.value} onChange={field.onChange} />}
                  label={t('internal-live-events:highlight_the_first_live_event')}
                />
              )
            }}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Button variant="text" sx={{ mr: 2 }} onClick={onCancel}>
          <Trans i18nKey="internal-common:cancel" />
        </Button>
        <Button type="submit" onClick={onSave} variant="contained" disabled={saveDisabled}>
          <Trans i18nKey="internal-common:save" />
        </Button>
      </Grid>
    </Grid>
  )
}
